
import { computed, defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import moment from "moment";
import { Mutations } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "profile-overview",
  components: {},
  setup() {
    const { t } = useI18n();

    const store = useStore();
    const user = store.getters.currentUser;

    const updated = ref<boolean>(false);

    const firstName = computed(() => user.employee.firstName);
    const lastName = computed(() => user.employee.lastName);
    const userName = computed(() => user.employee.userName);
    const photo = computed(() => user.employee.photo);
    const address = computed(() => user.employee.adress);
    const phone = computed(() => user.employee.phone);
    const birthday = computed(() => user.employee.birthDate);
    const cin = computed(() => user.employee.cin);
    const speciality = computed(() => user.speciality);
    const civilstatus = computed(() => user.employee.civilStatus);

    onMounted(() => {
      setCurrentPageBreadcrumbs(t("general"), [t("profile")]);
      if (store.getters.isUpdatedProfileAlert) {
        updated.value = true;
        setTimeout(() => {
          updated.value = false;
          store.commit(Mutations.SET_UPDATED_PROFILE_ALERT, false);
        }, 5000);
      }
    });

    return {
      t,
      updated,
      firstName,
      lastName,
      userName,
      photo,
      address,
      phone,
      birthday,
      cin,
      speciality,
      civilstatus,
      moment,
    };
  },
});
